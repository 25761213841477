.image-details-list-small {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .image-details-list-small .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .image-details-list-small img {
        width: 19vw;
        height: 19vw;
        object-fit: cover;
        margin-bottom: 20px;
    }

    .image-details-list-small button {
        height: 50px;
        border-radius: 5px;
        width: 150px !important;
        margin: 20px auto !important;
    }

    .image-details-list-small .description {
        padding: 20px 50px;
    }
