.about-alternating-list {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .about-alternating-list .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .about-alternating-list .list-item {
        background-color: white !important;
        border-radius: 50px;
        padding: 20px;
        border: 3px solid #888888;
        box-shadow: 0px 5px 5px #888888;
    }
