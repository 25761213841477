.welcome-split-small {
    background-image: url('../../images/waterstrange.jpg');
    background-size: calc(cover * 1.1);
    background-position: center;
    background-attachment: fixed;
    height: 400px;
    padding-top: 123px;
    box-shadow: 0px -5px 5px #888888 inset;
}

.welcome-split-small-container {
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
    border-radius: 50px;
    box-shadow: 0px 5px 5px #888888;
}

    .welcome-split-small img {
        width: 200px;
    }

    .welcome-split-small .overlay {
        background-color: #000000f0;
        width: calc(100% - 200px);
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .welcome-split-small .section-divider {
        border-bottom: 2px solid white;
        width:50%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .welcome-split-small button {
        height: 50px;
        border-radius: 5px;
    }

@media (min-width: 992px) {
    .welcome-split-small-container {
        height: 200px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .welcome-split-small img {
        border-radius: 50px 0 0 50px;
    }

    .welcome-split-small .overlay {
        border-radius: 0 50px 50px 0;
    }
}

@media (max-width: 991px) {
    .welcome-split-small {
        padding-top:120px;
        padding-bottom: 40px;
        height: auto;
    }

    .welcome-split-small-container {
        flex-direction: column;
        width: 90%;
        margin: auto;
    }

        .welcome-split-small img {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50px 50px 0 0;
        }

        .welcome-split-small .overlay {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 0 0 50px 50px;
        }
}
