.App-footer {
    background-color: white !important;
}

.App-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white !important;
    padding: 100px 0 50px 0;
    width: 100%;
}

    .App-footer a {
        text-decoration: none;
        color: #282c34;
    }
