.image-details-alternating-list {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .image-details-alternating-list .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .image-details-alternating-list img {
        height: 40vw;
        object-fit: cover;
        margin-bottom: 20px;
    }

    .image-details-alternating-list button {
        height: 50px;
        border-radius: 5px;
        width: 150px !important;
        margin: 20px auto !important;
    }

    .image-details-alternating-list .description {
        padding: 20px 50px;
    }
