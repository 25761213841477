@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body, #root {
    background-color: #e1f3ff !important;
}
.App {
  width:100%;
  background-color: #e1f3ff;
  min-height: 100vh;
}

.page_header {
    min-height: 80px;
}

.clear {
    clear: both;
}

.centered {
    margin: auto;
    text-align: center;
}
    
    .centered td {
        padding: 10px;
    }

button {
    border-radius: 5px !important;
    height: 50px;
    width: 150px;
}

.collapsed {
    overflow: hidden;
}

.collapsed:not(.show):not(.navbar-toggler) {
    height: 0;
    transition: height 1s;
}

.collapsed.show {
    transition: height 1s;
}