.details-list-small {
    padding-top: 40px;
    padding-bottom: 80px;
    margin: auto;
}

    .details-list-small .about-title {
        box-shadow: 0px 5px 5px #888888 inset;
        border-radius: 50px;
        padding: 10px 100px;
        background-color: whitesmoke;
    }

    .details-list-small img {
        width: 30%;
        margin-bottom: 20px;
    }

    .details-list-small .shekl img {
        width: 100%;
    }

    .details-list-small h1 {
        color: rgb(0 97 155);
    }

    .details-list-small .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .details-list-small .description .list-item {
        background-color: white !important;
        border-radius: 50px;
        width: 100%;
        padding: 20px;
        border: 3px solid #888888;
        box-shadow: 0px 5px 5px #888888;
    }

    .details-list-small .description-small .list-item {
        background-color: white !important;
        border-radius: 50px;
        width: 100%;
        padding-top: 100%;
        border: 3px solid #888888;
        box-shadow: 0px 5px 5px #888888;
        position: relative;
    }

        .details-list-small .description-small .list-item .img-div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
        }

            .details-list-small .description-small .list-item .img-div img {
                width: 100%;
                margin-bottom: 0px;
            }

    .details-list-small .description .img-div {
        margin-bottom: 0;
        align-self: center;
    }

        .details-list-small .description .img-div img {
            width: 80%;
            object-fit: cover;
            margin-top: 40px;
            margin-bottom: 40px;
        }

    .details-list-small button {
        height: 50px;
        border-radius: 5px;
        background-color: rgb(120 211 177);
        width: 300px;
    }

    .details-list-small .description {
        padding: 20px 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        line-height: 200%;
    }

        .details-list-small .description h1 {
            margin-bottom: 20px;
            line-height: 100%;
            color: rgb(120 211 177);
        }

    @media (min-width: 992px) {
        .details-list-small {
            width: 992px;
        }

        .details-list-small .description {
            padding: 0px 60px;
        }

        .details-list-small .shekl img {
            width: 80%;
        }

        .details-list-small .shekl {
            text-align: center;
        }
    }
    
    @media (max-width: 991px) {
        .details-list-small {
            width: 90vw;
        }

        .details-list-small .description {
            padding: 10px;
        }
    }
    