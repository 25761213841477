.contact-us {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .contact-us .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .contact-us .col-lg-8 {
        max-width: 600px;
    }

    .contact-us .bg-img {
        background-position: center;
        background-size: cover;
        color: #ffffff;
        height: 18vw;
    }

    .contact-us h1 {
        background-color: #000000a0;
    }
