.App-header {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: end;
    background-color: #242424;
    color: #fafafa !important;
    top: 0;
    left:0;
    position: fixed !important;
    box-shadow: 0px 1px 5px #888888;
}

    .App-header h3 {
        margin-left: 20%;
    }

    @media (max-width: 991px) {
        .App-header h3 {
            margin-left: 5%;
            margin-right: 22%;
        }
    }

    .App-header a, .App-header a:hover {
        text-decoration: none;
        color: #fafafa;
    }

    .App-header li a {
        color: #fafafa;
        font-size: 20px;
    }

.navbar-nav {
    margin: auto !important;
}

.hamburger {
    position: absolute !important;
    right: 10px;
    height: 70px;
    color: white;
}

    .hamburger button {
        width: 70px;
    }

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

button.navbar-toggler {
    border-color: white;
}