.captioned-text {
    padding-top: 80px;
    padding-bottom: 80px;
    margin: auto;
    width: 100%;
}

    .captioned-text .caption {
        margin: auto;
        background-color: #5f818b !important;
        padding: 20px;
        box-shadow: 0px 5px 5px #888888;
        width: 100%;
    }

    .captioned-text .about-title {
        max-width: 900px;
        margin: 50px auto;
        text-align: center;
        line-height: 45px;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 0px 5px 5px #888888 inset;
        background-color: #e1f3ff !important;
    }

    @media (min-width: 992px) {
        .captioned-text .caption {
        }
    }
    
    @media (max-width: 991px) {
        .captioned-text .caption {
        }
    }
    